<script>
import appConfig from "@/app.config";
import CreateForm from "@/views/pages/admin/team-members/components/form.vue";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    CreateForm,
  },
}
</script>

<template>
  <div>
    <div class="register-page register-template">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-4 col-xl-4">
            <div class="register-img">
              <div class="text-wrap">
                <img src="@/assets/images/register-image.png" class="h-100 object-cover" alt="">
              
              <p>Make Your Schedule Your Choice</p>
              <h1 class="head-01">Shift fish</h1>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-8 col-xl-8 min-height">
           
             <div class="m-lg-4 m-2 p-lg-4 p-2">
                <div class="">
                  <h2 class="head-02 fw-bold headColor">Register staff account</h2>
                </div>
                <div class="mt-4">
               <CreateForm :isManagement="false" :register="true"></CreateForm>
              </div>
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module></style>

